export default {
  // validações dados ats instituição
  entidadesErrors () {
    const errors = []
    if (!this.$v.item.entity.entityId.$dirty) return errors
    !this.$v.item.entity.entityId.required && errors.push('Seleção da Entitdade é obrigatório.')
    return errors
  },

  chipsErrors () {
    const errors = []
    if (!this.$v.item.chip.chipId.$dirty) return errors
    !this.$v.item.chip.chipId.required && errors.push('Seleção do chip é obrigatório.')
    return errors
  },

  deviceErrors () {
    const errors = []
    if (!this.$v.item.device.deviceId.$dirty) return errors
    !this.$v.item.device.deviceId.required && errors.push('Seleção do device é obrigatório.')
    return errors
  },

  passwordConfirmErrors () {
    const errors = []
    if (!this.$v.item.passwordConfirm.$dirty) return errors
    !this.$v.item.passwordConfirm.required && errors.push('Confirmar senha é obrigatório.')
    !this.$v.item.passwordConfirm.sameAsPassword && errors.push('Senhas não são iguais.')
    return errors
  },
  
  passwordErrors () {
    const errors = []
    if (!this.$v.item.password.$dirty) return errors
    !this.$v.item.password.required && errors.push('Senha é obrigatório.')
    return errors
  }
}
