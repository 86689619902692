<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal">
    <template v-slot:btnActivator>
      <v-btn
        tile
        v-if="permiteAcao($route, 'add')" 
        :color="variables.colorPrimary"
        dark
        class="mb-2 modal-cadastro-btn-cadastrar"
        @click="openModal"
        :loading="loadingBtnCadastro">Novo cadastro</v-btn>
    </template>
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          {{ isEdit ? `Editar Instalação: ${item.id} ` : 'Cadastrar Instalação' }}
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      
      <v-form v-else>
        <!-- <v-card-title class="modal-cadastro-titulo">
          
        </v-card-title> -->

        <v-card-text class="modal-cadastro-form">
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
              <!-- <v-select :error-messages="entidadesErrors" :items="entitiesListaItensSelect" label="Entidades" v-model="item.entity.entityId" @input="$v.item.entity.entityId.$touch()" @blur="$v.item.entity.entityId.$touch()"></v-select> -->

              <!-- <v-select 
                v-if="entitiesListaItensSelect.length < $store.getters.QuantidadeItensSelects" 
                :error-messages="entidadesErrors"
                :items="entitiesListaItensSelect"
                label="Entidades"
                v-model="item.entity.entityId"
                @input="$v.item.entity.entityId.$touch()"
                @blur="$v.item.entity.entityId.$touch()" />
               -->
              <v-autocomplete
                v-model="item.entity.entityId"
                :items="entitiesListaItensSelect"
                label="Entidades"
                @input="$v.item.entity.entityId.$touch()" 
                @blur="$v.item.entity.entityId.$touch()"
                v-on:keyup="searchAutoCompleteEntidades"
                :search-input.sync="searchTextEntidades"
                :error-messages="entidadesErrors"
                :loading="isLoadingAutoCompleteEntidades"/>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
              <!-- <v-select :error-messages="chipsErrors" :items="chipsListaItensSelect" label="Chips" v-model="item.chip.chipId" @input="$v.item.chip.chipId.$touch()" @blur="$v.item.chip.chipId.$touch()"></v-select> -->

              <!-- <v-select 
                v-if="$store.getters['chips/totalItemSelect'] < $store.getters.QuantidadeItensSelects" 
                :error-messages="chipsErrors"
                :items="chipsListaItensSelect"
                label="Chips"
                v-model="item.chip.chipId"
                @input="$v.item.chip.chipId.$touch()"
                @blur="$v.item.chip.chipId.$touch()" /> -->
              
              <!-- <v-autocomplete
                :error-messages="chipsErrors"
                v-model="item.chip.chipId"
                :items="chipsListaItensSelect"
                label="Chips"
                @input="$v.item.chip.chipId.$touch()" 
                @blur="$v.item.chip.chipId.$touch()" /> -->
              <v-autocomplete
                v-model="item.chip.chipId"
                :items="chipsListaItensSelect"
                label="Chips"
                @input="$v.item.chip.chipId.$touch()" 
                @blur="$v.item.chip.chipId.$touch()"
                v-on:keyup="searchAutoCompleteChips"
                :search-input.sync="searchTextChips"
                :error-messages="chipsErrors"
                :loading="isLoadingAutoCompleteChips"/>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
              <!-- <v-select :error-messages="deviceErrors" :items="devicesListaItensSelect" label="Device" v-model="item.device.deviceId" @input="$v.item.device.deviceId.$touch()" @blur="$v.item.device.deviceId.$touch()"></v-select> -->

              <!-- <v-select 
                v-if="$store.getters['devices/totalItemSelect'] < $store.getters.QuantidadeItensSelects" 
                :error-messages="deviceErrors"
                :items="devicesListaItensSelect"
                label="Device"
                v-model="item.device.deviceId"
                @input="$v.item.device.deviceId.$touch()"
                @blur="$v.item.device.deviceId.$touch()" /> -->
              
              <v-autocomplete
                v-model="item.device.deviceId"
                :items="devicesListaItensSelect"
                label="Device"
                @input="$v.item.device.deviceId.$touch()" 
                @blur="$v.item.device.deviceId.$touch()"
                v-on:keyup="searchAutoCompleteDevices"
                :search-input.sync="searchTextDevices"
                :error-messages="deviceErrors"
                :loading="isLoadingAutoCompleteDevices"/>
            </v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
              <v-text-field 
                :type="showPassword ? 'text' : 'password'" 
                :error-messages="passwordErrors"
                label="Senha"
                v-model="item.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                :autocomplete="autocomplete"
              />
            </v-col>

            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6">
              <v-text-field 
                :type="showPasswordConfirm ? 'text' : 'password'" 
                :error-messages="passwordConfirmErrors"
                label="Confirmar senha"
                v-model="item.passwordConfirm"
                :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPasswordConfirm = !showPasswordConfirm"
                :autocomplete="autocomplete"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="modal-cadastro-footer">
          <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
          <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="submit" class="br-btn" :loading="loading">{{ isEdit ? 'Salvar' : 'Cadastrar' }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, sameAs } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import { map, filter } from 'lodash'

import validacao from './validacao'

import autoCompleteEntidades from '../mixins/autoCompleteEntidades'
import autoCompleteChips from '../mixins/autoCompleteChips'
import autoCompleteDevices from '../mixins/autoCompleteDevices'

import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export default {
  name: 'BrModalCadastroOSC',
  mixins: [validationMixin, autoCompleteEntidades, autoCompleteChips, autoCompleteDevices],
  directives: {mask},

  components: {
    Modal: () => import('@/views/components/modal')
  },

  data: () => ({
    activeModal: false,
    loading: false,
    showPassword: false,
    showPasswordConfirm: false,
    loadingBtnCadastro: false,
    installations: [],
    itemInstallations: null,
    itemEditModal: null,
    autocomplete: 'new-password',
    loadingModal: false
  }),
  
  watch: {
    'item.id' (val) {
      if (val) {
        this.openModal(this.item)
      }
    }
  },

  computed: {
    // ...mapGetters('estabelecimento', { entitiesListaItensSelect: 'listaItensSelect' }),
    ...mapGetters('chips', { itensChips: 'listaItens' }),
    ...mapGetters('devices', { itensDevices: 'listaItens' }),
    ...mapGetters('installations', {
      item: 'item', 
      entitiesListaItensSelect: 'listaItensSelect',
      listaItensInstallations: 'listaItens'
    }),
    ...mapGetters('roles', ['permiteAcao']),
    ...validacao,
    variables: () => variables,
    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    // devicesListaItensSelect () {
    //   if (this.item.id) {
    //     const deviceInstalacao = {
    //       text: this.item.device.dados.serial,
    //       value: this.item.device.deviceId
    //     }
    //     return [ ...this.$store.getters['chips/listaItensSelectFilter'], deviceInstalacao ]
    //   }

    //   return null
    // },

    devicesListaItensSelect() {
      const listDevicesNotInstallation = map(this.itensDevices, item => {
        return {
          text: `${item.serial} (${this.tipoDevice(item.deviceTypeId)})`,
          value: item.id
        }
      })

      if (this.itemInstallations) {
        const deviceInstalacao = {
          text: `${this.itemInstallations[0].device.serial} (${this.tipoDevice(this.itemInstallations[0].device.deviceTypeId)})`,
          value: this.itemInstallations[0].device.deviceId
        }

        return [ ...listDevicesNotInstallation, deviceInstalacao  ]
      }

      return listDevicesNotInstallation
    },

    chipsListaItensSelect() {    
      const listChipsNotInstallation = map(this.itensChips, item => {
        return {
          text: item.iccid,
          value: item.id
        }
      })

      if (this.itemInstallations) {
        const chipInstallations = {
          text: this.itemInstallations[0].chip.iccid,
          value: this.itemInstallations[0].chip.chipId
        }

        return [ ...listChipsNotInstallation, chipInstallations  ]
      }

      return listChipsNotInstallation
    }
  },
  mounted () {
    // Events.$on('cadastro::openModalCadastro', () => {
    //   this.activeModal = true
    // })
  },
  methods: {
    ...mapActions('chips', {
      getChips: 'getItensNotInstallations',
    }),
    ...mapActions('devices', {
      getDevices: 'getItensNotInstallations'
    }),
    // ...mapActions('estabelecimento', {
    //   getEntities: 'obterItens'
    // }),
    ...mapActions('installations', ['cadastrarItem', 'editarItem', 'limparItem', 'getItens', 'obterItensSelectEstabelecimento', 'getItemView', 'deletarItem']),

    async openModal (item) {
      this.activeModal = true
      this.loadingModal = true

      if (!item.chip) {
        this.loadingBtnCadastro = true
      }
      await Promise.all([
        this.getChips({ pageSize: this.$store.getters.ItensPaginacao }),
        this.getDevices({ pageSize: this.$store.getters.ItensPaginacao }),
        this.obterItensSelectEstabelecimento({
          pageSize: this.$store.getters.ItensPaginacao 
        })
      ])
      .then(() => {
        if (item.chip) {
          this.itemInstallations = filter(this.listaItensInstallations, itemFilter => {
            return itemFilter.chip.chipId === item.chip.chipId
          })
        }
      })
      .catch((err) => {
        const msg = {
          toggle: true,
          type: 'error',
          msg: err.error
        }
        Events.$emit('snackbarCadastro::msg', msg)
      })
      .finally(() => {
        this.loadingBtnCadastro = false
        this.loadingModal = false
      })
    },

    closeModal () {
      this.activeModal = false
      this.limparItem()
      this.$v.item.$reset()
    },

    submit () {
      this.loading = true
      const self = this

      const dados = {
        entityId: self.item.entity.entityId,
        chipId: self.item.chip.chipId,
        deviceId: self.item.device.deviceId,
        password: self.item.password
      }


      if (self.item.id) {
        self.getItemView({id: self.item.id}).then(result => {
          if (self.item.chip.chipId !== result.data.chipId ||
            self.item.device.deviceId !== result.data.deviceId ||
            self.item.entity.entityId !== result.data.entityId) {
              Swal.fire({
                icon: 'warning',
                text: `Ao editar, a instalação sera deletada e criada uma nova`,
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não',
                allowEnterKey: false,
                customClass: {
                  container: 'container-popup',
                  confirmButton: 'br-btn',
                  cancelButton: 'br-btn br-btn-cancelar bt-btn-cancelar-popup'
                },
                showClass: {
                  popup: 'animated zoomIn'
                },
                hideClass: {
                  popup: 'animated zoomOut'
                },
                showCloseButton: true,
              })
              .then((result) => {
                if (result.value) {
                  self.item.id
                  if (self.$v.item.$invalid) { 
                    self.$v.item.$touch()
                    self.loading = false
                    return false 
                  }

                  self.deletarItem({id: self.item.id}).then(() =>{
                    self.cadastrarItem(dados).then(() => {
                      self.getItens({ page: 1, pageSize: self.$store.getters.ItensPaginacao })
                      self.closeModal()
                      self.loading = false
                      const msg = {
                        toggle: true,
                        type: 'success',
                        msg: 'Cadastro realizado com sucesso!'
                      }
                      Events.$emit('snackbarCadastro::msg', msg)
                    }).catch(err => {
                      self.loading = false
                      const msg = {
                        toggle: true,
                        type: 'error',
                        msg: err.error
                      }
                      Events.$emit('snackbarCadastro::msg', msg)
                    })
                  }).catch(err => {
                    const msg = {
                      toggle: true,
                      type: 'error',
                      msg: err.error
                    }
                    Events.$emit('snackbarCadastro::msg', msg)
                  })
                } else {
                  self.loading = false
                }
              })
          } else {
            if (self.$v.item.$invalid) { 
              self.$v.item.$touch()
              self.loading = false
              return false 
            }
            dados.id = self.item.id
            self.editarItem({ id: dados.id, password: dados.password }).then(() => {
              self.getItens({ page: 1, pageSize: self.$store.getters.ItensPaginacao })
              self.closeModal()
              self.loading = false
              const msg = {
                toggle: true,
                type: 'success',
                msg: 'Edição realizado com sucesso!'
              }
              Events.$emit('snackbarCadastro::msg', msg)
            }).catch(err => {
              self.loading = false
              const msg = {
                toggle: true,
                type: 'error',
                msg: err.msg
              }
              Events.$emit('snackbarCadastro::msg', msg)
            })
          }
        })

        // _.editarItem(dados).then(() => {
        //   _.getItens({ page: 1, pageSize: _.$store.getters.ItensPaginacao })
        //   _.closeModal()
        //   _.loading = false
        //   const msg = {
        //     toggle: true,
        //     type: 'success',
        //     msg: 'Edição realizado com sucesso!'
        //   }
        //   Events.$emit('snackbarCadastro::msg', msg)
        // }).catch(err => {
        //   _.loading = false
        //   const msg = {
        //     toggle: true,
        //     type: 'error',
        //     msg: err.msg
        //   }
        //   Events.$emit('snackbarCadastro::msg', msg)
        // })
      } else {
        if (self.$v.item.$invalid) { 
          self.$v.item.$touch()
          self.loading = false
          return false 
        }

        self.cadastrarItem(dados).then(() => {
          self.getItens({ page: 1, pageSize: self.$store.getters.ItensPaginacao })
          self.closeModal()
          self.loading = false
          const msg = {
            toggle: true,
            type: 'success',
            msg: 'Cadastro realizado com sucesso!'
          }
          Events.$emit('snackbarCadastro::msg', msg)
        }).catch(err => {
          self.loading = false
          const msg = {
            toggle: true,
            type: 'error',
            msg: err.error
          }
          Events.$emit('snackbarCadastro::msg', msg)
        })
      }

    },

    tipoDevice (typeDevice) {
      if (typeDevice === 1) return 'Android'
      if (typeDevice === 2) return 'iOS'

      return ''
    }
  },

  validations () {
    return  {
      item: {
        entity: {
          entityId: { required },
        },
        chip: {
          chipId: { required },
        },
        device: {
          deviceId: { required }
        },
        password: {
          required
        },
        passwordConfirm: { 
          required,
          sameAsPassword: sameAs('password')
        },
      },
    }
  }
}
</script>

<style lang="scss">
  @import '../../../../../assets/styles/components/formularios';
  @import '../../../../../assets/styles/components/modal-cadastro';
</style>