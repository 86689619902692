import { errorSnackbar } from '@/core/service/utils'

export default {
  data: () => ({
    isLoadingAutoCompleteChips: false,
    searchTextChips: null,
  }),

  methods: {
    searchAutoCompleteChips() {
     

      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchTextChips === null || vm.searchTextChips.length === 0) {
          vm.searchChips('')
          return
        }
        
        vm.searchChips(vm.searchTextChips)
      }, 600)
    },

    searchChips (val) {
      this.isLoadingAutoCompleteChips = true
      this.getChips({ 
        _filter: { "Chips:iccid_ilike":`${val}%` }
      })
      .catch(err => {
        errorSnackbar(err.error)
      })
      .finally(() => this.isLoadingAutoCompleteChips = false)
    }
  }
}