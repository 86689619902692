import { errorSnackbar } from '@/core/service/utils'

export default {
  data: () => ({
    isLoadingAutoCompleteEntidades: false,
    searchTextEntidades: null,
    debounce: null
  }),

  methods: {
    searchAutoCompleteEntidades() {
      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchTextEntidades === null || vm.searchTextEntidades.length === 0) {
          vm.searchEntities('')
          return
        }
        vm.searchEntities(vm.searchTextEntidades)
      }, 600)
    },

    searchEntities (val) {
      this.isLoadingAutoCompleteEntidades = true
      this.obterItensSelectEstabelecimento({ 
        _filter: { "Entities:jsonData.cod_ilike":`${val}%` }
      })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.isLoadingAutoCompleteEntidades = false)
    }
  }
}