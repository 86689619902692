import { errorSnackbar } from '@/core/service/utils'

export default {
  data: () => ({
    isLoadingAutoCompleteDevices: false,
    searchTextDevices: null,
    debounce: null
  }),

  methods: {
    searchAutoCompleteDevices() {
      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (this.searchTextDevices === null || this.searchTextDevices.length === 0) {
          this.search('')
          return
        }
        vm.search(vm.searchTextDevices)
      }, 600)
    },

    search (val) {
      this.isLoadingAutoCompleteDevices = true
      this.getDevices({ 
        _filter: { "Devices:serial_ilike":`${val}%` }
      })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.isLoadingAutoCompleteDevices = false)
    }
  }
}